.Content {
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

.container > p {
  font-size: 1rem;
}

.container > em {
  font-size: 0.8rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.FileItem {
    display: flex;
    align-items: flex-start;
  }
  
  .ResultDetails {
    color: #8f8f8f;
    font-size: small;
    list-style: none;
  }
  
  .iconContainer {
    margin-top: 15px;
  }
  
  .existsLabel {
    color: #af6262;
    font-size: smaller;
    font-style: italic;
  }
  
  .result {
    padding-inline-start: 0px;
  }
  
  .ResultDetails {
    color: #8f8f8f;
    font-size: small;
    list-style: none;
  }  