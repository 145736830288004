.noResultContainer {
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    align-items: center;
}

.noResultLabel {
    color: rgb(160, 157, 157);
    font-size: medium;
  }

.voided {
    font-style: italic;
    color: #6d6d6d;
}

.table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
}

.table td {
    padding: 0.75rem;
    vertical-align: top;
}

.table tr:not(:first-child) td {
    border-top: 1px solid rgba(224, 224, 224, 1);
}

.table .left {
    text-align: left;
}

.table .right {
    text-align: right;
}

@media (max-width: 600px ){
    .table-montos{
        width: 200px;
    }
    .table-details{
        width: 240px !important;
    }
}
