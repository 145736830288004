@media (max-width: 830px ){
    .header-container{
        justify-content: left !important;
    }
    
    .container-btn{
        margin: 1rem;
        flex-wrap: wrap;
    }
}

@media (max-width: 600px ){
    .container-btn{
        justify-content: center;
    }
    
}
@media (max-width: 569px ){
    .btn-date{
        margin: .8rem !important;
    }
}
@media (max-width: 367px ){
    .btn-filter{
        margin: auto !important;
    }
}
@media (max-width: 367px ){
    .btn-filter{
        margin: 0px !important;
    }
}

@media (max-width: 600px ){
    .header-title{
        flex-direction: column;
        font-size: 15px !important;
    }
}
@media (min-width: 600px ){
    .header-title{
        margin-bottom: 20px
    }  
}
@media  (max-width: 400px ) {
    .btnSizeLetra{
        font-size: 10px !important;
    }
}

@media  (min-width: 600px ) {
    .padding-general{
        padding-left: 25px !important;
    }
}
@media  (max-width: 600px ) {
    .padding-general{
        padding-left: 0px !important;
    }
}