.cardDireccion {
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   border: 1px solid #bbadad;
}

.autocomplete {
   padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      border-radius:4px;
}
.autocompleteDisable {
   padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      border-radius:4px;
      pointer-events: none;
      opacity: 0.3;
}

