.box-container {
    text-align: justify;
    margin: 5px 0px;
    padding: 5px 10px;
    display: block;
}

.line-warning {
    border-left: 5px solid #ffbb00;
}

.line-error {
    border-left: 5px solid #ff0000;
}

.line-info {
    border-left: 5px solid #435d8e;
}

.line-successful {
    border-left: 5px solid #00ba0c;
}

.line-transparent {
    border-left: 5px solid #ffbb00;
}

.box-warning {
    background: #fcf9dd;
    color: #636210;
}

.box-error {
    background: #ffefef;
    color: #631010;
}

.box-info {
    background: #e0eff1;
    color: #173458;
}

.box-successful {
    background: #e2ffe4;
    color: #1b591f;
}

.box-transparent {
    background: "transparent";
    color: #636210;
}

.note-box {
    padding: 5px 10px;
    margin-bottom: 1em;
    margin-top: 1em;
}

.box-title {
    display: block;
    white-space: pre;
    content: "Confirmar";
    font-size: 1.5rem;
    font-family: Corbel;
}
    
.box-body {
    font-size: 0.8rem;
}

.box-detail {
    padding-inline-start: 20px;
    margin-block-start: 5px;
    margin-block-end: 5px;
}